<template>
  <div class="breadcrumb">
    <div class="breadcrumb-item" @click="goBack">
      <span>สินค้า</span>
    </div>
    <div class="breadcrumb-item icon">
      <v-icon>
        mdi-chevron-right
      </v-icon>
    </div>
    <div class="breadcrumb-item active">
      <span>ยืนยันคำสั่งซื้อ</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutBreadcrumb',
  methods: {
    goBack() {
      this.$router.push({
        name: 'ProductDetail',
        params: { slug: this.$route.params?.slug },
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
.breadcrumb {
    display flex
    flex-direction row
    gap 3px
    margin 16px 0 0

    .breadcrumb-item{
        align-items center
        box-sizing border-box

        display flex
        flex-direction row
        font-family "SF Pro Text"
        font-size 12px
        font-style normal

        justify-content center
        letter-spacing -0.2px
        line-height 20px
        margin-bottom 0px
        margin-left 0px
        margin-right 0px
        margin-top 0px
        text-align center

        &:not(.icon):not(.active) {
            cursor pointer
        }

        &.active {
            font-weight 600
            color rgb(0, 113, 227)
        }
    }
}
</style>
